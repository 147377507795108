import styled from "styled-components";

export const TechnologiesTabsMain = styled.div`
  ${"" /* padding-top: 7rem; */}
  &.HomeTechnologiesTabsMain {
    padding-top: 7rem;
  }
  @media (max-width: 1024px) {
    ${
      "" /* padding-top: 1rem;
    padding-bottom: 4rem; */
    }
  }
  @media (max-width: 430px) {
    ${
      "" /* padding-top: 7rem;
    padding-bottom: 0rem; */
    }
  }
`;

export const TechnologiesHeading = styled.div`
  font-size: 58px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 46px;
    line-height: 60px;
  }
  @media (max-width: 820px) {
    font-size: 34px;
    line-height: 46px;
  }
  @media (max-width: 600px) {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const InnerTabsButton = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding: 13px 25px;
  border-radius: 12px;
  background: #f8f8f8;
  color: #000;
  cursor: pointer;
  min-width: fit-content;

  &.active {
    background: #ff0080;
    color: #fff;
  }
  &:hover {
    background: #ff0080;
    color: #fff;
  }
  @media (max-width: 414px) {
    font-size: 12px;
  }
`;

export const TechnologiesButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 3rem 0;
  position: relative;

  @media (max-width: 1200px) {
    gap: 1rem;
    width: 1200px;
  }
  @media (max-width: 820px) {
    gap: 0.5rem;
  }
  @media (max-width: 778px) {
    padding: 1.5rem 1rem;
    gap: 1.5rem;
    overflow: scroll;
  }
  @media (max-width: 600px) {
    width: 1130px;
  }
  @media (max-width: 430px) {
    width: 1130px;
  }
  @media (max-width: 390px) {
    width: 1040px;
  }
`;

export const TechnologiesContant = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 950px;
  margin: 0 auto;
  gap: 2rem;
  justify-content: center;
  padding: 2rem 0;
  padding-bottom: 0;

  &.SveltsJsTecho {
    max-width: 1200px;
  }
  &.LogoTransformMain {
    max-width: 1240px;
  }
  @media (max-width: 920px) {
    gap: 2rem;
  }
  @media (max-width: 778px) {
    min-height: 440px;
    &.LogoTransformMain {
      min-height: auto;
    }
  }
  @media (max-width: 600px) {
    column-gap: 0rem;
    column-gap: 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-template-rows: max-content;
    &.ProgressiveWebTechnologies {
      column-gap: 4rem;
    }
    &.HirePaythoneTchnoMain {
      gap: 2rem;
      grid-template-columns: 1fr 1fr;
      column-gap: 0px;
    }
    &.SoftwareTestingTechnologiesMain {
      gap: 2rem;
      grid-template-columns: 1fr 1fr;
      column-gap: 0px;
    }
    &.LogoTransformMain {
      column-gap: 1.5rem;
      gap: 1.5rem;
    }
  }
`;
export const TechnologiesContantMain = styled.div`
  width: 100%;
  overflow: auto;
`;

export const TabInnerDiv = styled.div`
  position: relative;
  width: 150px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    transition: all 0.5s ease;
  }
  svg {
    transition: all 0.5s ease;
  }

  &:hover {
    p {
      font-weight: 600;
    }
    img {
      transform: scale(1.05);
      transition: all 0.5s ease;
    }
    svg {
      transform: scale(1.05);
      transition: all 0.5s ease;
    }
    &:after {
      content: "";
      position: absolute;
      width: 150px;
      height: 150px;
      top: -12px;
      left: 0;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 12px;
    }
  }

  @media (max-width: 600px) {
    /* width: 18%; */
    min-height: 130px;

    img {
      height: 50px;
      width: 50px;
    }
    p {
      font-size: 14px;
      text-align: center;
    }
    &:hover {
      &:after {
        display: none;
      }
      img {
        transform: scale(1);
        transition: all 0.5s ease;
      }
    }
  }
  @media (max-width: 475px) {
    width: 100px;
    min-height: 100px;

    &:hover {
      &:after {
        width: 100px;
        height: 100px;
      }
    }
  }
`;
export const SveltsJsTabInnerDiv = styled.div`
  position: relative;
  width: 150px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
  border: 1px solid var(--border-color);

  &.LogoTransform {
    width: 23%;
    box-shadow: none;
    border-radius: 0px;
    background-color: #fdfdfd;
  }
  /* &.Product-Development{
    width: 25%;
  } */
  svg {
    transition: all 0.5s ease;
  }
  img {
    transition: all 0.5s ease;
  }

  &:hover {
    &.ProducDevelopment {
      outline: 10px solid #efeeee80;
      border-radius: 10px;
      background-color: #f8f8f8b5 !important;
      border: 0.5px solid #efeeee !important;
    }
    svg {
      transform: scale(1.05);
      transition: all 0.5s ease;
    }
    img {
      transform: scale(1.05);
      transition: all 0.5s ease;
    }
  }
  @media (max-width: 1180px) {
    &.LogoTransform {
      width: 25%;
    }
  }
  @media (max-width: 1024px) {
    &.LogoTransform {
      width: 29%;
    }
  }
  @media (max-width: 820px) {
    &.LogoTransform {
      width: 40%;
    }
  }
  @media (max-width: 600px) {
    width: 18%;

    img {
      min-height: 42px;
    }
    p {
      font-size: 14px;
      text-align: center;
    }
    &.HirePaythoneTchno {
      width: 85%;
    }
    &.SoftwareTestingTechnologies {
      width: 85%;
    }
    &.LogoTransform {
      min-height: 90px;
    }
  }
`;
export const OurWorkText = styled.div`
  font-size: 21px;
  color: #7a7b7c;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const PortfolioText = styled.div`
  font-size: 42px;
  line-height: 52px;
  font-weight: 400;

  @media (max-width: 1366px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media (max-width: 820px) {
    font-size: 28px;
    line-height: 38px;

    br {
      display: none;
    }
  }

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 35px;
    text-align: center;

    br {
      display: none;
    }
  }
`;

export const PortfolioMain = styled.div`
  padding: 4rem 0;

  @media screen and (min-width: 1440px) {
    padding: 8rem 0;
  }
  @media (max-width: 820px) {
    padding: 4rem 0;
  }

  @media (max-width: 600px) {
    padding: 4rem 0;
  }
`;

export const PortfolioRight = styled.div`
  padding-left: 0rem;

  @media (max-width: 778px) {
    padding-left: 0rem;
  }
  @media (max-width: 600px) {
    padding-top: 10px !important;
    text-align: center;
  }
`;

export const PortfolioCardsMain = styled.div`
  display: flex;
  background: ${(props) => (props.bg ? props.bg : ``)};
  color: ${(props) => (props.color ? props.color : ``)};
  ${"" /* min-height: 669px; */}
  margin-bottom: 3.5rem;
  border-radius: 6px;
  flex-wrap: wrap;
  overflow: hidden;
`;
export const PortfolioCardsLanding = styled.div`
  display: flex;
  background: ${(props) => (props.bg ? props.bg : ``)};
  color: ${(props) => (props.color ? props.color : ``)};
  min-height: 595px;
  margin-bottom: 3.5rem;
  border-radius: 6px;
  flex-wrap: wrap;
  overflow: hidden;
  @media (max-width: 820px) {
    justify-content: center;
  }
`;

export const PortfolioCardsInner = styled.div`
  width: 50%;
  padding: 5rem;
  padding-right: 0;

  @media (max-width: 1366px) {
    padding-left: 2rem;
  }
  @media (max-width: 1180px) {
    width: 100%;
    padding: 5rem;
  }
  @media (max-width: 820px) {
    width: 100%;
    padding: 2rem;
  }

  @media (max-width: 600px) {
    padding: 1.5rem;
  }
`;
export const PortfolioCardLanding = styled.div`
  width: 40%;
  padding: 5rem;
  padding-right: 0;

  @media (max-width: 820px) {
    width: 100%;
    padding: 2rem;
  }

  @media (max-width: 600px) {
    padding: 1.5rem;
  }
`;

export const PortfolioBreadcrumb = styled.div`
  font-size: 11px;
  font-weight: 400;

  &.mobilePage {
    color: #777777;
    font-size: 14px;
  }
`;

export const PortfolioHeading = styled.p`
  font-size: 31px;
  font-weight: 300;
  padding-top: 0rem;
  padding-bottom: 3.5rem;

  &.mobilePage {
    font-size: 25px;
    font-weight: 300;
    line-height: 42px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 1.5rem;

    &.mobilePage {
      font-size: 22px;
      line-height: 38px;
    }
  }

  @media (max-width: 778px) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  @media (max-width: 600px) {
    &.mobilePage {
      font-size: 16px;
      line-height: 30px;
    }
  }
`;
export const PortfolioButtonMobile = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;

  svg {
    height: 16px;
    width: 16px;
  }
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`;
export const PortfolioInnerText = styled.div`
  font-size: 13px;
  font-weight: 400;
  padding-right: 3.5rem;

  @media (max-width: 1025px) {
    padding-right: 3.5rem;
  }

  @media (max-width: 778px) {
    padding-right: 0rem;
    padding-bottom: 15px;
  }
`;

export const PortfolioButton = styled.button`
  display: flex;
  align-items: center;
  background: #fff;
  color: #000;
  padding: 10px 10px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  min-height: 40px;
  margin-top: 3.5rem;
  gap: 10px;

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    &.mysquard-button {
      box-shadow: rgb(242 242 242 / 65%) 0px 2px 21px -5px,
        rgb(255 255 255) 0px 9px 12px -11px;
    }
  }

  @media (max-width: 778px) {
    margin-top: 0.5rem;
  }
`;

export const PortfolioRightImage = styled.div`
  width: 44%;

  img {
    width: 100%;
    height: 100%;
    ${"" /* object-fit: contain; */}
  }

  &.mobilePage {
    display: flex;
    align-items: center;
    padding-left: 5rem;

    img {
      width: 448px;
      height: 618px;
      object-fit: unset;
    }
  }
  @media (max-width: 1366px) {
    &.mobilePage {
      padding-left: 2rem;
    }
  }
  @media (max-width: 1180px) {
    width: 100%;
    justify-content: center;
    img {
      object-fit: scale-down;
    }
    &.mobilePage {
      img {
        height: auto;
      }
    }
  }

  @media (max-width: 820px) {
    width: 100%;
    padding-top: 3rem;
    padding-left: 3rem !important;
  }
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 1.5rem !important;

    &.mobilePage {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export const PortfolioImage = styled.div`
  width: 60%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.mobilePage {
    display: flex;
    align-items: center;
    padding-left: 5rem;

    img {
      width: 448px;
      height: 618px;
      object-fit: unset;
    }
  }
  @media screen and (min-width: 1440px) {
    img {
      object-fit: contain;
    }
  }
  @media (max-width: 1025px) {
    img {
      object-fit: cover;
    }
  }

  @media (max-width: 820px) {
    width: 100%;
    padding-top: 3rem;
    padding-left: 3rem !important;

    &.right-mobile {
      width: 65% !important;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 1.5rem !important;

    &.mobilePage {
      img {
        width: 100%;
        height: auto;
      }
    }
    &.right-mobile {
      width: 100% !important;
    }
  }
`;

export const PortfolioBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowProjectsButton = styled.button`
  font-weight: 400;
  min-height: 47px;
  padding: 10px 15px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #000000;
  color: #fff;

  .more-icon {
    rotate: 228deg;
  }

  @media (max-width: 600px) {
    min-height: unset;
    font-size: 14px;
  }
`;

export const ServicesMain = styled.div`
  margin-bottom: 8rem;
  width: 100%;
  &.MerndAd {
    margin-bottom: 0rem;
  }
  &.demanddesign {
    padding-top: 0rem;
  }
  @media (max-width: 1024px) {
    /* padding-top: 6rem; */
    &.demanddesign {
      padding-top: 0rem;
    }
  }
  @media (max-width: 820px) {
    padding-top: 3rem;
    margin-top: 0;
  }
  @media (max-width: 778px) {
    padding-top: 3rem;
    margin-top: 0;
    margin-bottom: 0rem;
    width: 100%;

    &.codi-nater {
      padding-bottom: 4.5rem;
    }

    &.ELearningDesign {
      padding-top: 0rem;
      margin-top: 0rem;
    }
  }

  @media (max-width: 820px) {
    &.demanddesign {
      padding-top: 5rem;
    }
  }
  @media (max-width: 430px) {
    &.demanddesign {
      padding-top: 1rem;
    }
    &.RestaurantDesign {
      padding-top: 0rem;
    }
    &.RealDesign {
      padding-top: 0rem;
      padding-bottom: 0;
    }
  }

  &.WeVedDesigned {
    @media (max-width: 820px) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0rem;
      .WeVedDesignedTitle {
        width: 100%;
      }
      .demanddesign {
        padding-top: 5rem;
      }
    }
  }
  &.WeVedDesigned {
    @media (max-width: 778px) {
      margin-top: 0;
    }
  }
  @media (max-width: 600px) {
    &.oddoHealth {
      margin-top: 0;
    }
    margin-top: 0;
  }
`;
export const ServicesBottomIcon = styled.div`
  position: absolute;
  top: -446px;
  right: -223px;
  @media (max-width: 1180px) {
    right: -141px;
  }
  @media (max-width: 1024px) {
    margin-top: 5.5rem;
    right: -165px;
    svg {
      width: 245px;
    }
    img {
      width: 245px;
    }
  }
  @media (max-width: 914px) {
    right: 0px;
    top: -430px;
    display: none;
    svg,
    img {
      width: 50%;
    }
  }
  @media (max-width: 768px) {
    svg,
    img {
      display: none;
    }
  }
`;
export const ServiceHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ServicesLeft = styled.div`
  &.projectTeam {
    width: 60%;
  }
  &.HomeServicesLeft {
    margin-bottom: 60px;
  }
  @media (min-width: 1440px) {
  }
  @media (max-width: 768px) {
    &.projectTeam {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    width: 100%;

    &.projectTeam {
      width: 100%;
    }
  }
  @media (max-width: 479px) {
    margin-bottom: 30px;
  }
`;

export const ServicesLabel = styled.div`
  font-size: 17px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
  padding-top: 30px;
  max-width: 550px;
  margin: 0 auto;
  &.WVedDesignedText {
    font-size: 26px;
    line-height: 36px;
  }
  @media (min-width: 1440px) {
    padding-top: 50px;
  }
  @media (max-width: 1024px) {
    &.WVedDesignedText {
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media (max-width: 820px) {
    &.WVedDesignedText {
      font-size: 16px;
      line-height: 26px;
    }
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    margin-bottom: 16px;
  }
  @media (max-width: 479px) {
    padding-top: 20px;
  }
  @media (max-width: 430px) {
    &.WVedDesignedText {
      font-size: 14px;
      line-height: 22px;

      br {
        display: none;
      }
    }
  }
`;

export const ServiceInner = styled.div`
  font-size: 56px;
  font-weight: 600;
  line-height: 65px;
  text-align: center;
  text-transform: capitalize;

  @media (max-width: 1024px) {
    font-size: 35px;
    font-weight: 500;
    line-height: 45px;
    padding-left: 0rem;
  }
  &.WVedDesignedTitle {
    font-size: 46px;
    line-height: 56px;
  }
  @media (max-width: 820px) {
    &.WVedDesignedTitle {
      font-size: 34px;
      line-height: 46px;
      /* br {
        display: none;
      } */
    }
  }
  @media (max-width: 600px) {
    letter-spacing: -0.5px;
    font-size: 26px;
    line-height: 36px;
  }

  @media (max-width: 375px) {
    &.WVedDesignedTitle {
      font-size: 21px;
      line-height: 30px;
    }
  }
  @media (max-width: 430px) {
    &.WVedDesignedTitle {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none;
      }
    }
    &.RealDesignTitle {
      font-size: 26px;
      line-height: 30px;
    }
  }
`;

export const ServiceRight = styled.div`
  font-size: 16px;
  font-weight: 400;
  max-width: 50%;
  width: 25%;

  &.projectTeam {
    max-width: 40%;
  }
  @media (max-width: 1366px) {
    width: 32%;
  }
  @media (max-width: 820px) {
    width: 40%;
  }
  @media (max-width: 778px) {
    width: 100%;
    max-width: 100%;
    padding-top: 1rem;

    &.projectTeam {
      max-width: 100%;
    }
  }
  @media (max-width: 768px) {
    &.projectTeam {
      text-align: center;
    }
  }
`;
export const ServiceTopCard = styled.div`
  width: 80%;
  margin: auto;

  @media (max-width: 1024px) {
    width: 50%;
    margin: unset;
  }
  @media (max-width: 769px) {
    width: 80%;
    margin: auto;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin: auto;
  }
`;
export const ServicesMiddleCard = styled.div`
  margin: 12rem 0;
  margin-top: 0rem;
  background: #1d1e22;
  border: 1px solid #414141;
  border-radius: 16px;
  padding: 1.5rem 0;
  width: 542px;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0.5rem 0;
    margin-bottom: 11rem;
    width: 40%;
    padding-bottom: 1rem;
    padding-top: 1rem !important;
  }
  @media (max-width: 914px) {
    width: 48%;
  }
  @media (max-width: 820px) {
    width: 45%;
    &.WeVedDesignedCard {
      padding-bottom: 16px;
      width: 45%;
      margin-bottom: 6rem;
    }
  }

  @media (max-width: 778px) {
    width: 100%;
    margin: 2rem 0;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    padding-top: 5px;
    width: 100%;
    &.WeVedDesignedCard {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 475px) {
    width: 100%;
    &.WeVedDesignedCard {
      width: 100%;
      margin-bottom: 1rem;
    }
    &.home-main-industries {
      margin-bottom: 200px;
    }
  }
`;
export const ServicesInnerCard = styled.div`
  width: 540px;
  min-height: auto;
  ${"" /* max-height: 304px; */}
  position: absolute;
  bottom: -180px;
  right: -300px;
  border: 1px solid #f6f4f2;
  border-radius: 14px;
  padding: 2rem;
  background: #ffffff;
  padding-right: 5rem;
  @media (min-width: 1400px) {
    width: 540px;
    right: -365px;
    padding-right: 3rem;
  }
  @media (max-width: 1200px) {
    width: 540px;
    right: -350px;
    padding: 2rem;
    padding: 2rem;
  }
  @media (max-width: 1024px) {
    right: -335px;
    width: 480px;
  }

  @media (max-width: 820px) {
    padding: 2rem;
    width: 420px;
    right: -346px;
    min-height: 200px;
    bottom: -90px;
    &.WeVedDesignedCardBottom {
      position: unset;
      width: 100%;
      margin-top: 4rem;
      padding: 1rem;
    }
  }
  @media (max-width: 540px) {
    position: relative;
    width: 100%;
    padding: 1rem;
    left: 0;
    /* margin-bottom: 0.5rem; */
    bottom: -20px;
  }
  @media (max-width: 475px) {
    position: relative;
    width: 100%;
    padding: 1rem;
    left: 0;
    /* margin-bottom: 0.5rem; */
    bottom: -20px;
    &.home-industries {
      position: absolute;
      width: 100%;
      padding: 1rem;
      left: 0;
      bottom: -220px;
    }
    &.oddoCard {
      top: 18px;
    }
  }
  @media only screen and (max-width: 950px) and (max-height: 500px) {
    svg {
      display: none;
    }
  }
`;
export const RecommendedInnerSide = styled.div`
  @media (max-width: 1024px) {
    margin-top: 6rem;
  }
  @media (max-width: 914px) {
    margin-top: 10rem;
  }
  @media (max-width: 820px) {
    margin-top: 2rem;

    .titleRecommended {
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 786px) {
    margin-top: 12rem;

    .titleRecommended {
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 600px) {
    margin-top: 0;

    .mobileRecommended {
      margin-top: 0;
      gap: 0.5rem;
    }
  }
`;
export const RecommendedInnerRow = styled.div`
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  @media (max-width: 820px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-row-gap: 0px;
  }
`;
export const RecommendedInnerHeadingMain = styled.div`
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  &.counter {
    margin-bottom: 50px;
  }
  @media (min-width: 1440px) {
    &.counter {
      margin-bottom: 100px;
    }
  }
  @media (max-width: 991px) {
  }
  @media (max-width: 600px) {
    justify-content: center;
    &.counter {
      flex-direction: row;
    }
    flex-direction: column;
    /* align-items: start; */
    gap: 30px;
    margin-bottom: 10px;
  }
  @media (max-width: 320px) {
    &.counter {
      flex-direction: column;
    }
  }
`;
export const RecommendedH2 = styled.div`
  font-weight: 600;
  font-size: 84px;
  line-height: 92px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  &.formtitle,
  &.countertitle {
    font-size: 60px;
    line-height: 70px;
  }

  @media (max-width: 1024px) {
    &.formtitle,
    &.countertitle {
      font-size: 46px;
      line-height: 56px;
    }
    font-size: 50px;
    line-height: 60px;
    align-items: end;
    row-gap: 5px;
  }
  @media screen and (max-width: 991px) {
    &.formtitle,
    &.countertitle {
      font-size: 34px;
      line-height: 46px;
    }
    &.clutch-review-h2 {
      margin-left: 20px;
    }
  }
  @media (max-width: 600px) {
    font-size: 30px;
    line-height: 40px;
    align-items: end;
    row-gap: 5px;
    &.formtitle,
    &.countertitle {
      font-size: 26px;
      line-height: 36px;
      text-align: center;
    }
    &.clutch-review-h2 {
      margin-left: 10px;
    }
  }
`;

export const RecommendedInnerCol = styled.div``;
export const ServicesInnerText = styled.div`
  font-size: 21px;
  font-weight: 400;
  line-height: 32px;
  font-family: Merriweather;

  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 820px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 26px;
  }
`;
export const ServicesItems = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  margin-bottom: 0px;
  width: fit-content;
  .rightCross {
    rotate: 43deg;
    margin-left: 10px;
  }
  svg {
    right: 0;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    position: relative;
  }
  img {
    right: 0;
    transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    position: relative;
  }
  &:hover {
    svg {
      right: -10px;
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    }
    img {
      right: -10px;
      transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
    }
  }
  @media (max-width: 1024px) {
    font-size: 20px;
    line-height: 26px;
    padding: 1rem 1.5rem;
  }

  @media (max-width: 820px) {
    font-size: 15px;
    line-height: 18px;
    padding: 1rem 1.5rem;
  }
  @media (max-width: 778px) {
    min-height: 40px;
    border-radius: 16px;
    padding: 1rem 1.5rem;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    min-height: 35px;
  }
`;

export const ServicesItemsInner = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  color: #ffffff;

  svg {
    height: 26px;
    width: 26px;
  }
  @media (max-width: 1024px) {
    span {
      display: none;
    }
    font-size: 20px;
    line-height: 30px;
  }
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 15px;
  }
  @media (max-width: 600px) {
    span {
      display: none;
    }
    font-size: 14px;
    line-height: 15px;
  }
`;

export const TestimonialsHeading = styled.div`
  text-align: center;
`;

export const TestimonialsHead = styled.div`
  font-size: 42px;
  font-weight: 600;
  @media (max-width: 778px) {
    font-size: 32px;
  }
`;

export const TestimonialsMain = styled.div`
  padding: 0 0 10em 0;
  position: relative;
  @media (max-width: 778px) {
    padding: 0 0 3em 0;
  }
  &::before {
    content: " ";
    width: 90%;
    margin: 0 auto;
    height: 35vh;
    border-radius: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 190px;
    z-index: -1;
    display: inline-block;
    background: linear-gradient(
      90deg,
      #dcfcfb,
      #99cbe7,
      #d5bdda,
      #fab6b6,
      #fff1d2,
      #dcfcfb,
      #99cbe7,
      #d5bdda,
      #fab6b6,
      #fff1d2
    );
    background-size: 300% 300%;
    animation: gradient-animation 15s ease infinite;
    filter: blur(50px);
    -webkit-filter: blur(50px);
    @media (max-width: 778px) {
      height: 50vh;
    }
  }

  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export const TestimonialsSliderMain = styled.div`
  .slick-slider {
    margin-left: -10px;
    margin-right: -10px;
    .slick-slide {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;
export const TestimonialsStars = styled.div`
  display: flex;
  align-items: center;
`;
export const TestimonialsItems = styled.div`
  background: #fff;
  margin: 1rem 0;
  padding: 2rem;

  svg {
    height: 26px;
    width: 26px;
    color: #ff9d28;
  }
`;

export const TestimonialsContant = styled.div`
  font-size: 16px;
  font-weight: 400;
  padding-top: 2rem;
`;

export const TestimonialsBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-top: 4rem;

  img {
    border-radius: 50%;
    height: 46px;
    width: 46px;
  }
`;

export const BottomInner = styled.div``;

export const ReviewName = styled.div`
  font-size: 17px;
  font-weight: 600;
`;

export const ReviewNameInner = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

export const TestimonialsBottomContant = styled.div`
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
`;

export const ProjectTeamInner = styled.div`
  font-size: 42px;
  font-weight: 600;
  line-height: 57px;

  @media (max-width: 1366px) {
    font-size: 32px;
    line-height: 50px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: 820px) {
    font-size: 25px;
    line-height: 40px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
    line-height: 40px;
  }
  @media (max-width: 430px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const ProjectTeamMain = styled.div`
  ${
    "" /* padding: 5rem 0;
  margin-top: 5rem;
  margin-bottom: 5rem;
  @media (max-width: 778px) {
    margin-top: 0rem;
    padding: 3rem 0;
  } */
  }
`;

export const ProjectTeamCards = styled.div`
  padding: 3rem 0;
  display: flex;
  align-items: stretch;
  gap: 1rem;

  @media (max-width: 1366px) {
    flex-wrap: wrap;
  }

  @media (max-width: 778px) {
    //flex-direction: column;
    width: 100%;
    overflow: scroll;
  }
`;

export const ProjectListCard = styled.div`
  position: relative;
  margin-top: 6rem;
  padding: 4rem 2rem 0 2rem;
  width: 24.1%;
  // min-width: 321px;
  min-height: 385px;

  span {
    height: 8px;
    width: 8px;
    display: block;
    background: #fff;
    border-radius: 50%;
    outline: 4px solid #fc8ec5;
  }
  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 300;
  }

  ul {
    list-style: unset;
    padding-top: 10px;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 20px;
    li {
      padding: 6px 0px 0px 0px;
      list-style: none;
      position: relative;
      margin-bottom: 20px;
      ${
        "" /* &:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        width: 21px;
        height: 22px;
        background: url(https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/round-with-check-orange.svg) center center no-repeat;
        filter: invert(0) brightness(0);
        background-size: cover;
      } */
      }
    }
  }

  @media (max-width: 1366px) {
    width: 32%;
  }
  @media (max-width: 1024px) {
    width: 48.1%;
  }
  @media (max-width: 820px) {
    width: 48%;
    margin-top: 3rem;
  }
  @media (max-width: 600px) {
    width: 100%;
    min-width: 100%;
    margin-top: 3rem;
  }
`;
export const ProjectListTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  min-height: 51px;
  padding-right: 65px;
`;
export const ProjectListInner = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #58595c;
  padding-top: 20px;
`;

export const ProjectListLabel = styled.div`
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ProjectLabelInner = styled.div`
  height: 57px;
  width: 57px;
  border: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--background-color);
`;
export const LearnSkillsMain = styled.div`
  //margin-top: 10rem;
`;
export const LearnSkillsHeader = styled.div`
  text-align: center;
`;
export const LearnHeading = styled.div`
  font-size: 42px;
  font-weight: 400;
  line-height: 51px;
  width: 60%;
  margin: auto;

  @media (max-width: 1024px) {
    width: 70%;
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 778px) {
    width: 70%;
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: 600px) {
    width: 100%;
    font-size: 30px;
    line-height: 40px;
  }
`;
export const LearnInnerText = styled.div`
  font-size: 17px;
  font-weight: 400;
  width: 33%;
  margin: auto;
  padding-top: 20px;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 778px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const LearnCards = styled.div`
  display: flex;
  gap: 1rem;
  align-items: stretch;
  margin-top: 7rem;

  @media (max-width: 820px) {
    flex-wrap: wrap;
  }
  @media (max-width: 778px) {
    flex-direction: column;
    margin-top: 3rem;
  }
`;
export const LearnCardsInner = styled.div`
  border: 0.5px solid var(--border-color);
  border-radius: 4px;
  padding: 2rem 0.5rem 2rem 2.5rem;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 1.5rem 0.5rem 1.5rem 1.5rem;
  }

  @media (max-width: 778px) {
    width: 70%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }

  ul {
    padding-left: 0;
    margin-top: 1.6rem;
  }
  li {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 7px 0;
    font-size: 14px;
    font-weight: 400;
  }
`;
export const LIspan = styled.div`
  height: 11px;
  width: 11px;
  display: block;
  background: ${(props) => (props.bg ? props.bg : ``)};
`;
export const LearnCardsTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
`;
export const LearnCardSub = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-right: 25px;
`;
export const StartBuildingMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: 1rem;
    .SeoServicesTechnologies {
      flex-wrap: wrap;
      gap: 2rem;
    }
  }
`;
export const StartInnerText = styled.div`
  font-size: 52px;
  font-weight: 600;
  line-height: 62px;
  width: 70%;
  text-align: center;
  padding-top: 2rem;

  @media (max-width: 1024px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
    width: 80%;
  }

  @media (max-width: 778px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
  }
  @media (max-width: 600px) {
    font-size: 26px;
    line-height: 37px;
    width: 100%;
    padding-top: 0rem;
  }
`;
export const GetEstimation = styled.button`
  font-size: 16px;
  font-weight: 600;
  border-radius: 50px;
  padding: 10px 25px;
  min-height: 47px;
  color: #fff;
  min-width: 214px;
  margin-top: 1rem;
  &.iso-logo-btn {
    min-width: 190px;
  }
`;
export const ReadOurBlogMain = styled.div`
  text-align: center;
  padding: 6rem 0;
  background: #f8fafc;
  margin: 3rem 0;
`;
export const ReadOurHeader = styled.div``;
export const ReadHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2563eb;
`;
export const ReadMainHead = styled.div`
  font-size: 52px;
  font-weight: 600;
  line-height: 62px;
  color: #090914;
  /* padding */
`;
export const ReadOurBody = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 4rem 0;
  gap: 5rem;

  @media (max-width: 600px) {
    padding: 4rem 1rem;
  }
`;
export const ReadBlogItem = styled.div`
  width: 315px;

  img {
    width: 100%;
    height: auto;
    min-height: 206px;
    border-radius: 4px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const BlogName = styled.div`
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  padding-top: 2rem;
`;
export const BlogLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #2563eb;
  text-align: left;
  padding-top: 1rem;
`;
export const StillAboutMain = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5rem;
  padding: 5rem 0;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`;
export const StillLeftSide = styled.div`
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const StillHeading = styled.div`
  font-size: 42px;
  font-weight: 400;
  line-height: 49px;
`;
export const StillSub = styled.div`
  font-size: 21px;
  font-weight: 400;
  line-height: 27px;
  padding-top: 2rem;
`;
export const StillRightSide = styled.div`
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const ReferEarnAccordion = styled.div`
  .accordion-item {
    border: 0;
    border-radius: 0;
    border-bottom: 0.4px solid #c7c7c7;
  }
  .accordion-item:last-child {
    border-bottom: 0;
  }
  .accordion-button::after {
    transform: rotate(-92deg);
  }
  .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg);
  }
  .accordion-header {
    width: 100%;
  }
  .accordion-button:not(.collapsed) {
    color: unset;
    background-color: unset;
    box-shadow: none;
  }
  .accordion-button:focus {
    border: 0;
    box-shadow: none;
  }
  .accordion-button {
    padding-left: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 34px;
    gap: 2rem;
  }
  .accordion-body {
    padding-left: 0;
    padding-bottom: 2rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
  }
  .FaqBg {
    background-color: var(--background-color);
  }
  .FaqColor button.accordion-button.collapsed {
    color: var(--text-color);
  }
  .FaqColor button.accordion-button {
    color: var(--text-color);
  }
  .FaqBg .accordion-collapse.collapse.show {
    color: var(--text-color);
  }
  @media (max-width: 600px) {
    .accordion-button {
      padding-left: 0;
      padding-right: 0;
      min-height: 80px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.7;
    }
    .accordion-body {
      font-size: 13px;
      font-weight: 400;
    }
    .accordion-button::after {
      width: 20px;
      height: 15px;
    }
  }
`;
export const ModalHeadingSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
export const CloseIconModal = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const ModalName = styled.div`
  font-size: 19px;
  font-weight: 600;
  padding-top: 1rem;
`;
export const ModalInnerItems = styled.div`
  font-size: 52px;
  font-weight: 600;
  line-height: 57px;
  padding-top: 2rem;

  @media (max-width: 778px) {
    font-size: 32px;
    font-weight: 600;
    line-height: 37px;
    padding-top: 2rem;
  }
`;
export const ModalSubInner = styled.div`
  font-size: 19px;
  font-weight: 400;
  padding-top: 1rem;
`;
export const InnerButtonsModal = styled.div`
  padding-top: 2rem;
  font-size: 16px;
  font-weight: 400;
  color: #2997ff;
  cursor: pointer;
`;

export const ModalSliderMain = styled.div`
  padding: 1.5rem 1.5rem 2rem 3rem;
`;

export const RecommendedHeader = styled.div``;

export const RecommendedTestimonial = styled.div`
  background: ${(props) => (props.bg ? props.bg : `#1D1E22`)};
`;

export const FrequentlyaskedFAQ = styled.div`
  .accordion-item {
    border: none !important;
  }

  .accordion {
    /* padding-bottom: 5vh; */
    --bs-accordion-color: #212529;
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #000 !important;
    --bs-accordion-border-width: 0 !important;
    --bs-accordion-btn-icon: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"%2F%3E%3C%2Fsvg%3E') !important;
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="currentColor" d="M19 12.998H5v-2h14z"%2F%3E%3C%2Fsvg%3E') !important;
    --bs-accordion-btn-focus-border-color: none !important;
    --bs-accordion-btn-focus-box-shadow: none !important;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #000 !important;
    --bs-accordion-active-bg: transparent !important;
  }

  .accordion-button {
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 36px;
    letter-spacing: -0.2px;
    padding: 1.3rem 1rem !important;

    &.collapsed {
      border-bottom: 1px solid #ddd !important;
    }

    @media (max-width: 768px) {
      font-size: 18px !important;
      padding: 0.7rem !important;
      display: flex;
      align-items: flex-start;
    }
  }

  .accordion-body {
    padding-top: 0.1rem !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    /* color: #55595d !important; */
  }

  .last_button {
    .accordion-button {
      &.collapsed {
        border-bottom: 0 !important;
      }
    }
  }

  @media (max-width: 1024px) {
    .accordion-button {
      font-size: 18px !important;
      padding-left: 0;
    }
    .accordion-body {
      font-size: 14px !important;
      padding-left: 0;
    }
  }
`;

export const FrequentlyaskedTitle = styled.div`
  padding: 3vh 0;
  font-size: 42px;
  font-weight: 500;

  @media (max-width: 1024px) {
    font-size: 28px;
    padding: 2vh 0;
  }
`;

export const AccordionDiv = styled.div``;

export const AppFaqSectionMain = styled.div`
  .accordion-item {
    border: none !important;
    background: transparent;
  }
  .AccordionBodyImg .accordion-body {
    padding: 0;
  }

  .accordion {
    /* padding-bottom: 5vh; */
    --bs-accordion-color: rgb(255, 255, 255);
    --bs-accordion-btn-padding-x: 0rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #000 !important;
    --bs-accordion-border-width: 0 !important;
    --bs-accordion-btn-icon: url('data:image/svg+xml,%3Csvg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M17.4259 18.1417L29 6.85044L26.0781 4L14.504 15.2912L2.92995 4L0 6.85044L14.504 21L17.4259 18.1417Z" fill="%23ABBC3B"/%3E%3C/svg%3E') !important;
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url('data:image/svg+xml,%3Csvg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M17.4259 18.1417L29 6.85044L26.0781 4L14.504 15.2912L2.92995 4L0 6.85044L14.504 21L17.4259 18.1417Z" fill="%23ABBC3B"/%3E%3C/svg%3E') !important;
    --bs-accordion-btn-active-icon-transform: rotate(90deg);
    --bs-accordion-btn-focus-border-color: transparent !important;
    --bs-accordion-btn-focus-box-shadow: none !important;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #000 !important;
    --bs-accordion-active-bg: transparent !important;
  }

  .accordion-button {
    font-size: 42px !important;
    font-weight: 500 !important;
    line-height: 48px;
    letter-spacing: -0.2px;
    padding: 1.3rem 0rem !important;
    color: #fff !important;
    background: transparent;
    border-radius: unset !important;

    &::after {
      display: none;
    }
    &.collapsed {
      border-top: 1px solid #898989;
      border-radius: unset !important;
    }

    @media (max-width: 768px) {
      font-size: 18px !important;
      padding: 0.7rem !important;
      display: flex;
      align-items: flex-start;
    }
  }

  .accordion-collapse.collapse.show {
    .Accordion-img {
      transform: scale(1) rotate(0);
      opacity: 1 !important;
      visibility: visible;
    }
  }

  .accordion-body {
    padding-top: 1.1rem !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgb(255, 255, 255) !important;
    padding-left: 4rem;

    .Accordion-img {
      transform: scale(0.8) rotate(-20deg);
      opacity: 0 !important;
      transition: all 500ms cubic-bezier(0.24, 0.01, 0, 0.83);
    }
  }

  .last_button {
    .accordion-button {
      &.collapsed {
        border-bottom: 0 !important;
      }
    }
  }

  @media (max-width: 1024px) {
    .accordion-button {
      font-size: 18px !important;
      padding-left: 0;
    }
    .accordion-body {
      font-size: 14px !important;
      padding-left: 0;
    }
  }
  @media (max-width: 600px) {
    .accordion-body {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
`;
